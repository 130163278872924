import type { ThemeDefinition } from 'vuetify'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { VNumberInput } from 'vuetify/labs/VNumberInput'
import colors from 'vuetify/lib/util/colors'
import 'vuetify/styles'

const light: ThemeDefinition = {
  dark: false,
  colors: {
    primary: colors.red.darken2,
    accent: colors.grey.darken3,
    secondary: colors.blueGrey.darken3,
    info: colors.teal.lighten1,
    warning: colors.amber.base,
    error: colors.deepOrange.accent4,
    success: colors.green.lighten1,
  },
}

const dark: ThemeDefinition = {
  dark: true,
  colors: {
    primary: colors.red.darken2,
    accent: colors.grey.darken3,
    secondary: colors.blueGrey.darken3,
    info: colors.teal.lighten1,
    warning: colors.amber.base,
    error: colors.deepOrange.accent4,
    success: colors.green.lighten1,
  },
}

export default createVuetify({
  defaults: {
    VProgressLinear: { color: 'primary' },
    VTabs: { color: 'primary' },
    VCheckbox: { color: 'primary' },
    VRadioGroup: { color: 'primary' },
    VFileInput: { variant: 'underlined', color: 'primary' },
    VTextField: { variant: 'underlined', color: 'primary' },
    VTextarea: { variant: 'underlined', color: 'primary' },
    VSelect: { variant: 'underlined', color: 'primary' },
    VAutocomplete: { variant: 'underlined', color: 'primary' },
    VStepperItem: { color: 'primary' },
    VDataTable: {
      hover: true,
      showCurrentPage: true,
      itemsPerPageOptions: [
        { value: 30, title: '30' },
        { value: 50, title: '50' },
        { value: 100, title: '100' },
      ],
    },
    VDataTableServer: {
      hover: true,
      showCurrentPage: true,
      itemsPerPageOptions: [
        { value: 30, title: '30' },
        { value: 50, title: '50' },
        { value: 100, title: '100' },
      ],
    },
  },
  theme: {
    defaultTheme: localStorage.getItem('theme') ?? 'light',
    themes: {
      light,
      dark,
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  components: {
    VNumberInput,
  },
})
