import type { IHistory } from '@/interfaces/history'
import type { IMonitoring, IParamsFilter, IThcPaginated } from '@/interfaces/thc'
import { get, post } from './ajaxutils'

export default {
  getAmbients(params: IParamsFilter): Promise<IThcPaginated> {
    return get('/api/thc/ambients', { params: JSON.stringify(params) })
  },
  getHistory(ambient_id: number | null): Promise<Array<IHistory>> {
    return get('/api/thc/ambient/history', { ambient_id })
  },
  addAmbientObservation(ambient_id: number | null, observation: string): Promise<void> {
    return post('/api/thc/ambient/observation/add', {
      ambient_id,
      observation,
    })
  },
  getMonitorings(ambient_id: number | null): Promise<Array<IMonitoring>> {
    return get('/api/thc/ambient/monitorings', { ambient_id })
  },
  saveMonitoring(monitoring: IMonitoring): Promise<Array<IMonitoring>> {
    return post('/api/thc/ambient/monitoring/save', monitoring)
  },
  removeMonitoring(monitoring_id: number | null): Promise<Array<IMonitoring>> {
    return post('/api/thc/ambient/monitoring/remove', { monitoring_id })
  },
}
