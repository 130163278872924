import type { IUser } from '@/interfaces/user'
import i18n from '@/plugins/i18n'
import { defineStore } from 'pinia'
import api from '~api'

interface IAuth {
  authenticated: boolean
  currentUser: IUser | null
}

export const useAuthStore = defineStore('auth', {
  state: () =>
    ({
      authenticated: false,
      currentUser: null,
    }) as IAuth,
  getters: {
    loggedIn: state => state.authenticated,
    // Roles
    isSuperUser: state => !!state.currentUser?.permissions?.IS_SUPER_USER,
    isStaff: state => !!state.currentUser?.permissions?.IS_STAFF,
    isBoard: state => !!state.currentUser?.permissions?.IS_BOARD,
    isSales: state => !!state.currentUser?.permissions?.IS_SALES,
    isSalesReadOnly: state => !!state.currentUser?.permissions?.IS_SALES_READ_ONLY,
    isSalesInternational: state => !!state.currentUser?.permissions?.IS_SALES_INTERNATIONAL,
    isSampleRoom: state => !!state.currentUser?.permissions?.IS_SAMPLE_ROOM,
    isTechnicalAreaManager: state => !!state.currentUser?.permissions?.IS_TECHNICAL_AREA_MANAGER,
    isTechnicalArea: state => !!state.currentUser?.permissions?.IS_TECHNICAL_AREA,
    isFinance: state => !!state.currentUser?.permissions?.IS_FINANCE,
    isQualityManagerGlp: state => !!state.currentUser?.permissions?.IS_QUALITY_MANAGER_GLP,
    isStudyDirectorGlp: state => !!state.currentUser?.permissions?.IS_STUDY_DIRECTOR_GLP,
    isTestInstallationManagerGlp: state => !!state.currentUser?.permissions?.IS_TEST_INSTALLATION_MANAGER_GLP,
    // Products
    canManagePop: state => !!state.currentUser?.permissions?.CAN_MANAGE_POP,
    // Entry documents
    canManageEntryDocument: state => !!state.currentUser?.permissions?.CAN_MANAGE_ENTRY_DOCUMENT,
    // Study plans
    canGenerateGlp: state => !!state.currentUser?.permissions?.CAN_GENERATE_GLP,
    canGeneratePatchTest: state => !!state.currentUser?.permissions?.CAN_GENERATE_PATCH_TEST,
    // Recruitment
    isTaRecruitmentManager: state => !!state.currentUser?.permissions?.IS_TA_RECRUITMENT_MANAGER,
    isTaRecruitmentCoordinator: state => !!state.currentUser?.permissions?.IS_TA_RECRUITMENT_COORDINATOR,
    isRecruitmentManager: state => !!state.currentUser?.permissions?.IS_RECRUITMENT_MANAGER,
    isRecruitmentCoordinator: state => !!state.currentUser?.permissions?.IS_RECRUITMENT_COORDINATOR,
    isRecruitmentOperator: state => !!state.currentUser?.permissions?.IS_RECRUITMENT_OPERATOR,
    isRecepcionist: state => !!state.currentUser?.permissions?.IS_RECEPCIONIST,
    // Finance
    canRequestPayment: state => !!state.currentUser?.permissions?.CAN_REQUEST_PAYMENT,
    canManageDocumentaryPayment: state => !!state.currentUser?.permissions?.CAN_MANAGE_DOCUMENTARY_PAYMENT,
    canReviewBilling: state => !!state.currentUser?.permissions?.CAN_REVIEW_BILLING,
  },
  actions: {
    async whoami(): Promise<void> {
      try {
        const data = await api.auth.whoami()
        this.authenticated = data.authenticated

        if (this.authenticated) {
          this.currentUser = data.user
        } else {
          this.currentUser = null
        }

        // @ts-ignore
        i18n.global.locale.value = data?.user?.language ?? localStorage.getItem('language') ?? 'pt'
      } catch {
        //
      }
    },
    setCurrentUser(user: IUser): void {
      this.authenticated = true
      this.currentUser = user
    },
    removeCurrentUser(): void {
      this.authenticated = false
      this.currentUser = null
    },
  },
})
