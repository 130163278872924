import attendance from './attendance'
import auth from './auth'
import customer from './customer'
import entryDocument from './entry-document'
import group from './group'
import notification from './notification'
import participant from './participant'
import payment from './payment'
import product from './product'
import proposal from './proposal'
import proposalHistory from './proposal-history'
import recruitment from './recruitment'
import schedule from './schedule'
import select from './select'
import studyPlan from './study-plan'
import thc from './thc'
import zipcode from './zipcode'

export default {
  attendance,
  auth,
  customer,
  entryDocument,
  group,
  notification,
  product,
  participant,
  proposal,
  proposalHistory,
  recruitment,
  schedule,
  select,
  studyPlan,
  payment,
  zipcode,
  thc,
}
